<template>
    <div class="w-full bg-base-200 text-base-content/80">
        <footer class="container flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-6 p-4 py-12">
            <form class="flex w-full flex-wrap justify-center gap-4" @submit.prevent="subscribe">
                <Teleport to="body">
                    <TransitionSlide appear :offset="[0, -50]">
                        <div v-show="success"
                             aria-live="polite"
                             role="alert"
                             class="alert alert-success fixed left-1/2 top-4 z-50 m-0 max-w-max -translate-x-1/2
                                    cursor-pointer bg-success/90 text-white"
                             @click="success = false">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="size-6 shrink-0 stroke-current"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            Thank you for subscribing!
                        </div>
                    </TransitionSlide>
                </Teleport>

                <div class="w-full grow text-center">
                    <h4 class="w-full grow font-bold text-base-content/60">
                        Stay in the know
                    </h4>
                    <p>
                        Subscribe to our newsletter to get the latest updates, tips and tricks, and offers!
                    </p>
                </div>
                <input v-model="name"
                       autocomplete="name"
                       type="text"
                       class="input w-full sm:w-auto"
                       name="name"
                       placeholder="Name"
                       required>
                <input v-model="email"
                       type="email"
                       autocomplete="email"
                       class="input w-full sm:w-auto"
                       placeholder="Email Address"
                       required>
                <button class="btn btn-primary 2xs:btn-wide ">
                    Subscribe
                </button>
                <span class="w-full text-center text-xs text-base-content/40">
                    This site is protected by reCAPTCHA and the Google
                    <a class="underline" href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a class="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.
                </span>
            </form>

            <div class="mx-auto flex flex-col items-center gap-2 xs:mx-0">
                <Logo class="size-12 text-base-content/60 sm:size-20" />
                <p class="mb-2 text-xs text-base-content/50 xs:hidden">
                    2022 © by Cina Delicious Cakes
                </p>
            </div>

            <div class="flex items-center justify-between gap-x-12">
                <div>
                    <p class="mb-2 hidden text-xs text-base-content/50 xs:block">
                        2022 © by<br>Cina Delicious Cakes
                    </p>
                    <nav>
                        <p class="mb-2 select-none text-sm font-bold">
                            Socials:
                        </p>
                        <div class="flex items-center justify-evenly gap-2">
                            <NuxtLink external
                                      href="https://www.facebook.com/CinaCDC"
                                      target="_blank"
                                      aria-label="Facebook"
                                      class="inline-block"
                                      rel="noopener">
                                <FacebookIcon />
                            </NuxtLink>
                            <NuxtLink external
                                      href="https://www.instagram.com/cina_delicious_cakes/"
                                      target="_blank"
                                      aria-label="Instagram"
                                      class="inline-block"
                                      rel="noopener">
                                <InstagramIcon />
                            </NuxtLink>
                            <NuxtLink external
                                      href="https://www.tiktok.com/@cinasdeliciouscakes"
                                      target="_blank"
                                      aria-label="TikTok"
                                      class="inline-block"
                                      rel="noopener">
                                <TiktokIcon />
                            </NuxtLink>
                        </div>
                    </nav>
                </div>

                <nav class="flex flex-col gap-y-2 text-center text-sm xs:text-left sm:text-base">
                    <NuxtLink :to="{ name: 'index' }">
                        Home Page
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'products' }">
                        Products
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'galleries' }">
                        Galleries
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'about' }">
                        About
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'faqs' }">
                        Frequently Asked Questions
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'contact' }">
                        Contact
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'cookie-policy' }">
                        Cookie Policy
                    </NuxtLink>
                </nav>
            </div>

            <p class="w-full select-none text-center text-xs text-base-content/40 md:text-left">
                Made by <NuxtLink target="_blank" rel="noopener" href="https://nandor.kraszlan.dev">
                    Nandor Kraszlan
                </NuxtLink>
            </p>

            <div class="flex w-full flex-wrap items-center justify-center gap-2">
                <NuxtLink v-for="location in locations"
                          :key="location"
                          :to="{ name: 'slug-cakes', params: { slug: simpleSlug(location) } }"
                          class="text-xs opacity-40">
                    Cakes in {{ location }}
                </NuxtLink>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { locations } from '~/fixtures';
import apiFetch from '~/utils/apiFetch';
import { useChallengeV3 } from 'vue-recaptcha';

export default defineComponent({
    name: 'FooterSection',
    setup: () => {
        const name = ref('');
        const email = ref('');
        const success = ref(true);
        let recaptcha: ReturnType<typeof useChallengeV3>['execute'] | undefined;

        if (environment('production')) {
            recaptcha = useChallengeV3('contact_form').execute;
        }

        const subscribe = async () => {
            const resp = recaptcha ? await recaptcha() : undefined;

            return apiFetch('newsletter/subscribe', {
                method: 'POST',
                body: {
                    name: name.value,
                    email: email.value,
                    challenge: resp
                }
            })
                .then(() => {
                    useTrackEvent('newsletter-subscribe', {
                        name: name.value,
                        email: email.value
                    });

                    success.value = true;
                    setTimeout(() => success.value = false, 4000);

                    name.value = email.value = '';
                });
        };


        return {
            locations,
            simpleSlug,
            subscribe,
            name,
            email,
            success
        };
    }
});
</script>
