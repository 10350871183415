<template>
    <div class="grid place-content-center items-center justify-around justify-items-center gap-2
                text-white sm:grid-cols-2">
        <NuxtLink v-for="category in categories"
                  v-once
                  :key="category.type"
                  :to="{ name: 'products', query: { type: category.type } }"
                  class="group relative">
            <NuxtPicture :src="category.image"
                         loading="lazy"
                         height="500"
                         :alt="`${category.title} category`"
                         width="1000"
                         :img-attrs="{ class: 'rounded-lg' }" />
            <div class="absolute inset-0 flex h-full flex-col items-center justify-center
                        opacity-100 transition-opacity group-hover:opacity-0">
                <!-- eslint-disable vue/no-v-html -->
                <h2 class="rounded-lg bg-accent/90 px-4 py-2 text-lg font-semibold shadow sm:text-2xl"
                    v-html="category.title" />
            </div>
            <div class="absolute inset-0 flex h-full flex-col items-center justify-center
                        bg-black/50 p-3 opacity-0 transition-opacity group-hover:opacity-100">
                <p class="text-center text-sm text-white lg:text-base">
                    {{ category.description }}
                </p>
            </div>
        </NuxtLink>
    </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import type { ProductType } from '~/composables/useProductTypes';

interface Category {
    type: ProductType;
    title: string;
    description: string;
    image: string;
}
export default defineComponent({
    name: 'CategoryGrid',

    setup: () => {
        const categories = markRaw<Category[]>([
            {
                type: 'cake',
                title: 'Cakes',
                description: 'The most delicious cakes await in a variety of flavors and decorations.',
                image: '/images/categories/cake.jpg'
            },
            {
                type: 'pastry',
                title: 'Pastries',
                description: 'If you just want a few slices of sweets, you can choose here. I know it\'s hard to ' +
                    'choose from so many delicacies, but give it a try.',
                image: '/images/categories/pastry.jpg'
            },
            {
                type: 'pie-tart',
                title: 'Pies &amp; Tarts',
                description: 'If you fancy a simple yet great dessert on weekdays, choose a pie or tart with a ' +
                    'golden, flaky exterior that cradles a flavorful filling.',
                image: '/images/categories/pie.jpg'
            },
            {
                type: 'bread',
                title: 'Breads',
                description: 'The bread  throughout recorded history and around the world, it has been an important ' +
                    'part of many cultures\' diet. It is one of the oldest human-made foods. In this album you ' +
                    'will find some of mine.',
                image: '/images/categories/bread.jpg'
            },
            {
                type: 'dessert',
                title: 'Desserts',
                description: 'Desserts are enjoyed for their rich flavors and indulgent qualities, providing a ' +
                    'satisfying and sweet conclusion to dining experiences. Pamper yourself and your guests with them.',
                image: '/images/categories/dessert.jpg'
            },
            {
                type: 'savoury',
                title: 'Savoury Treats',
                description: 'These savoury are the perfect snack at any time, for any occasion.',
                image: '/images/categories/savoury.jpg'
            },
            {
                type: 'cupcake',
                title: 'Cupcakes',
                description: 'Cupcakes are a popular treat for holidays and everyday enjoyment. If you like them, ' +
                    'you can find some here.',
                image: '/images/categories/cupcake.jpg'
            },
            {
                type: 'patisserie',
                title: 'Pâtisserie',
                description: 'This album offers sweet indulgence and culinary elegance with its delicious cakes.',
                image: '/images/categories/patisserie.jpg'
            }
        ]);

        return {
            categories
        };
    }
});
</script>
