import payload_plugin_qLmFnukI99 from "/home/runner/work/cdc-web/cdc-web/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/cdc-web/cdc-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/cdc-web/cdc-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_JRId4KOeUL from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import nuxt_plugin_WPDdiystzB from "/home/runner/work/cdc-web/cdc-web/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import plugin_client_LcKgStRyi6 from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import connectivity_dA0v1W4A6B from "/home/runner/work/cdc-web/cdc-web/src/plugins/connectivity.ts";
import error_client_w6UV4qYPD5 from "/home/runner/work/cdc-web/cdc-web/src/plugins/error.client.ts";
import router_client_50wbVZQ4Lc from "/home/runner/work/cdc-web/cdc-web/src/plugins/router.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/cdc-web/cdc-web/src/plugins/sentry.client.ts";
import defaults_M8ptihKv0z from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  nuxt_plugin_WPDdiystzB,
  plugin_client_LcKgStRyi6,
  connectivity_dA0v1W4A6B,
  error_client_w6UV4qYPD5,
  router_client_50wbVZQ4Lc,
  sentry_client_KAXFuL2wum,
  defaults_M8ptihKv0z
]